
import { Paper, Typography, Box, Button, Divider, Grid, Badge } from "@mui/material";
import { useEffect, useState } from "react";
import { useLayout } from "providers/LayoutProvider";
import { Delete, MaxRune, decodificar, getCharacters, getSkills, getSkill, getTitle, getTitles, removePk, reward, updateNation, updatePoints, getStyle, checkStyle, removeStyle, getReset } from "services/characterService";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import GroupIcon from '@mui/icons-material/Group';
import { useForm } from "react-hook-form";
import FormTextField from "components/Form/FormTextField";
import FormNumber from "components/Form/FormNumber";
import { literal, number, object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCash } from "providers/CashProvider";
import LinearProgress from '@mui/material/LinearProgress';
import PaperHeader from "components/PaperHeader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Banner from "components/Banner";
import { URL } from "config/CONSTANTS";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


function Characters() {

  const [characters, setCharacters] = useState(null);
  const [selectedCharacter, setSelectedCharacter] = useState(0);
  const { mobile, showSuccessDialog, closeSuccessDialog, setLoading, showErrorDialog,
    closeErrorDialog, showAlertDialog, closeAlertDialog } = useLayout();
  const { updateCash } = useCash();
  const { getStringLanguage, language } = useLayout();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const meritos = [1, 2, 3, 4, 5, 6, 7]


  const {
    control,
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
    setValue,
    getValues
  } = useForm({});

  useEffect(() => {
    setLoading(true);
    reloadCharacters(() => {
      setLoading(false);
    });
  }, [])

  const reloadCharacters = (callback) => {
    getCharacters()
      .then(res => {
        res.forEach((element, index) => {
          element.class = decodificar(element.style, language)
          getTitles(element.characterIdx)
            .then((resTiles) => {

              element.titles = resTiles;

              getSkills(element.characterIdx)
                .then((resSkills) => {

                  element.skills = resSkills;

                  checkStyle(element.characterIdx)
                    .then((resStyle) => {
                      console.log(resStyle);
                      element.style21 = resStyle.style ? resStyle : null;
                      element.resets = resStyle.resets == null ? 0 : resStyle.resets;


                      if (index + 1 == res.length) {
                        setCharacters(res);
                        reset(res[selectedCharacter]);
                        updateCash();
                        callback();
                      }


                    })
                    .catch((err) => { });


                })
                .catch((err) => { });

            })
            .catch((err) => { });
        });

      })
      .catch((err) => {
        setCharacters(null)
        callback();
      });
  }

  const handleChange = (event, newValue) => {
    setSelectedCharacter(newValue);
    reset(characters[newValue]);
  };

  const pntResolver = (ev, pnt) => {
    var value = parseInt(ev.target.value == "" || ev.target.value == null ? 0 : ev.target.value);
    if (value < 0) {
      value = 0
    }
    var lastValue = getValues(pnt);
    if (value < lastValue) {
      var freePoints = getValues("pnt");
      setValue("pnt", freePoints + (lastValue - value))
      setValue(pnt, value);
    } else {
      var freePoints = getValues("pnt");
      var dif = value - lastValue;
      if (dif > freePoints) {
        setValue("pnt", 0)
        setValue(pnt, lastValue + freePoints);
      } else {
        setValue("pnt", freePoints - dif)
        setValue(pnt, value);
      }
    }
  }

  const handleOnSubmit = (formBody) => {
    setLoading(true);
    updatePoints(formBody)
      .then((res) => {
        reloadCharacters(() => {
          setLoading(false);
          showSuccessDialog(getStringLanguage("Pontos alterado com sucesso"), () => {
            closeSuccessDialog();
          })
        })
      })
      .catch((err) => {
        setLoading(false);
        showErrorDialog(err.message, () => {
          closeErrorDialog();
        })
      });
  }

  const changeNation = (id) => {
    var character = characters[selectedCharacter];
    if (character.nation != id) {
      showAlertDialog(getStringLanguage("Tem certeza que deseja utilizar 300k Cash para alterar sua nação para ") + (id == 1 ? "Capella" : "Procyon"),
        () => {
          updateNation({ characterIdx: character.characterIdx, nation: id })
            .then((res) => {
              reloadCharacters(() => {
                setLoading(false);
                showSuccessDialog(getStringLanguage("Nação alterada com sucesso"), () => {
                  closeSuccessDialog();
                })
              })
            })
            .catch((err) => {
              setLoading(false);
              showErrorDialog(err.message, () => {
                closeErrorDialog();
              })
            });
          closeAlertDialog();
        },
        () => {
          closeAlertDialog();
        })
    }
  }

  const obterTitle = (id) => {
    var character = characters[selectedCharacter];
    showAlertDialog(getStringLanguage("Tem certeza que deseja obter este titulo ?"),
      () => {
        getTitle(character.characterIdx, id)
          .then((res) => {
            reloadCharacters(() => {
              setLoading(false);
              showSuccessDialog(getStringLanguage("Titulo resgatado com sucesso"), () => {
                closeSuccessDialog();
              })
            })
          })
          .catch((err) => {
            setLoading(false);
            showErrorDialog(err.message, () => {
              closeErrorDialog();
            })
          });
        closeAlertDialog();
      },
      () => {
        closeAlertDialog();
      })
  }

  const removePkClick = () => {
    var character = characters[selectedCharacter];
    removePk(character.characterIdx)
      .then((res) => {
        reloadCharacters(() => {
          setLoading(false);
          showSuccessDialog(getStringLanguage("Pk removido com sucesso"), () => {
            closeSuccessDialog();
          })
        })
      })
      .catch((err) => {
        setLoading(false);
        showErrorDialog(err.message, () => {
          closeErrorDialog();
        })
      });
    closeAlertDialog();
  }

  const rewardClick = () => {
    setLoading(true);
    var character = characters[selectedCharacter];
    reward(character.characterIdx)
      .then((res) => {
        reloadCharacters(() => {
          setLoading(false);
          showSuccessDialog(getStringLanguage(res.message), () => {
            closeSuccessDialog();
          })
        })
      })
      .catch((err) => {
        setLoading(false);
        showErrorDialog(err.message, () => {
          closeErrorDialog();
        })
      });
    closeAlertDialog();
  }

  const deletClick = () => {
    var character = characters[selectedCharacter];

    showAlertDialog(getStringLanguage("Tem certeza que deseja utilizar 50k Cash para deletar o personagem?\nSeu personagem será deletado definitivamente, e não nós responsabilizamos por qualquer decisão tomada."),
      () => {
        setLoading(true);
        Delete(character.characterIdx)
          .then((res) => {
            reloadCharacters(() => {
              setLoading(false);
              showSuccessDialog(getStringLanguage("Personagem deletado com sucesso"), () => {
                closeSuccessDialog();
              })
            })
          })
          .catch((err) => {
            setLoading(false);
            showErrorDialog(err.message, () => {
              closeErrorDialog();
            })
          });
        closeAlertDialog();
      },
      () => {
        closeAlertDialog();
      })
  }

  const maxClick = (type) => {
    var character = characters[selectedCharacter];

    if (type == 1) {
      showAlertDialog(getStringLanguage("Tem certeza que deseja utilizar 500k Cash para atualizar as runas essenciais do personagem?"),
        () => {
          setLoading(true);
          MaxRune(character.characterIdx, 1)
            .then((res) => {
              reloadCharacters(() => {
                setLoading(false);
                showSuccessDialog(getStringLanguage("Personagem runas atualizadas."), () => {
                  closeSuccessDialog();
                })
              })
            })
            .catch((err) => {
              setLoading(false);
              showErrorDialog(err.message, () => {
                closeErrorDialog();
              })
            });
          closeAlertDialog();
        },
        () => {
          closeAlertDialog();
        })
    } else {
      showAlertDialog(getStringLanguage("Tem certeza que deseja utilizar 3kk Cash para atualizar as runas essenciais + runas elemental?"),
        () => {
          setLoading(true);
          MaxRune(character.characterIdx, 2)
            .then((res) => {
              reloadCharacters(() => {
                setLoading(false);
                showSuccessDialog(getStringLanguage("Personagem runas atualizadas."), () => {
                  closeSuccessDialog();
                })
              })
            })
            .catch((err) => {
              setLoading(false);
              showErrorDialog(err.message, () => {
                closeErrorDialog();
              })
            });
          closeAlertDialog();
        },
        () => {
          closeAlertDialog();
        })
    }

  }




  const claimSkill = (itemid) => {
    var character = characters[selectedCharacter];
    showAlertDialog(getStringLanguage("Tem certeza que deseja obter esta skill por 50 war fragments ?"),
      () => {
        setLoading(true);
        getSkill(character.characterIdx, itemid)
          .then((res) => {
            reloadCharacters(() => {
              setLoading(false);
              showSuccessDialog(getStringLanguage("Skill enviada com sucesso, cheque o e-mail do personagem"), () => {
                closeSuccessDialog();
              })
            })
          })
          .catch((err) => {
            setLoading(false);
            showErrorDialog(err.message, () => {
              closeErrorDialog();
            })
          });
        closeAlertDialog();
      },
      () => {
        closeAlertDialog();
      })
  }

  const claimStyle = () => {
    var character = characters[selectedCharacter];
    showAlertDialog(getStringLanguage("Tem certeza que deseja obter grau de classe 21 por 1000 war fragments?"),
      () => {
        setLoading(true);
        getStyle(character.characterIdx)
          .then((res) => {
            reloadCharacters(() => {
              setLoading(false);
              showSuccessDialog(getStringLanguage("Parabéns personagem atualizado para grau 21"), () => {
                closeSuccessDialog();
              })
            })
          })
          .catch((err) => {
            setLoading(false);
            showErrorDialog(err.message, () => {
              closeErrorDialog();
            })
          });
        closeAlertDialog();
      },
      () => {
        closeAlertDialog();
      })
  }

  const claimReset = (cust) => {
    var character = characters[selectedCharacter];
    showAlertDialog(getStringLanguage("Tem certeza que deseja resetar seu personagem por " +cust+" war fragments?"),
      () => {
        setLoading(true);
        getReset(character.characterIdx)
          .then((res) => {
            reloadCharacters(() => {
              setLoading(false);
              showSuccessDialog(getStringLanguage("Parabéns personagem resetado"), () => {
                closeSuccessDialog();
              })
            })
          })
          .catch((err) => {
            setLoading(false);
            showErrorDialog(err.message, () => {
              closeErrorDialog();
            })
          });
        closeAlertDialog();
      },
      () => {
        closeAlertDialog();
      })
  }

  const remStyle = () => {
    var character = characters[selectedCharacter];
    showAlertDialog(getStringLanguage("Tem certeza que deseja remover grau de classe 21 por 500k Cash, seus 1000 war fragmentos voltaram para conta?"),
      () => {
        setLoading(true);
        removeStyle(character.characterIdx)
          .then((res) => {
            reloadCharacters(() => {
              setLoading(false);
              showSuccessDialog(getStringLanguage("Parabéns grau 21 removido"), () => {
                closeSuccessDialog();
              })
            })
          })
          .catch((err) => {
            setLoading(false);
            showErrorDialog(err.message, () => {
              closeErrorDialog();
            })
          });
        closeAlertDialog();
      },
      () => {
        closeAlertDialog();
      })
  }


  return (
    <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }} className="flex_col align_center">

      {<Banner image={require("assets/characters.jpg")} height={'400px'}
        position={mobile ? 'center' : "100% 25%"} title={getStringLanguage("Personagens")} />}

      <Box sx={{ width: mobile ? '100%' : 1250, padding: mobile ? '15px' : 0 }}>

        <Box sx={{ width: '100%' }} marginY={2}>
          <PaperHeader icon={<GroupIcon color="primary" fontSize="large" />}
            title={getStringLanguage("Personagens")} />
        </Box>

        {characters != null &&
          <Box><Tabs
            value={selectedCharacter}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {characters.map((character, i) => <Tab
              key={i}
              sx={{ alignItems: 'center' }}
              textColor="secondary"
              icon={<b>({character.class.classSingle})</b>}
              label={<span sx={{ fontSize: '5px' }}>{character.name}</span>} {...a11yProps(i)}
              iconPosition="top" />)}

          </Tabs></Box>}

        <Box sx={{ width: '100%', paddingBottom: '30px' }}>

          {characters != null && <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', padding: 0 }}
          >

            {characters.map((character, i) => <TabPanel
              key={i}
              value={selectedCharacter} index={i}>

              <Paper elevation={4} sx={{ padding: mobile ? '15px' : '50px' }} mb={3}>
                <Box className="flex_col align_center">
                  <Badge color={characters[selectedCharacter].login == 1 ? 'success' : 'error'} overlap="circular" badgeContent=" ">
                    <Box mb={2} className="classIcon">
                      <img width="32" height="32" src={require('assets/' + character.class.classSingle + '.png')} />
                    </Box>
                  </Badge>
                  <Typography variant="h10" sx={{ lineHeight: "15px", textAlign: 'center' }}>
                    <b>{character.name}</b>
                  </Typography>
                  <Typography variant="h10" sx={{ lineHeight: "15px", textAlign: 'center' }}>
                    {character.class.className}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ lineHeight: "15px", textAlign: 'center' }}>
                    {"Level " + character.lev + " (" + character.resets + ")"}
                  </Typography>
                </Box>

                <Box mt={2} component="form" onSubmit={handleSubmit(handleOnSubmit)}>
                  <Grid container spacing={1} alignItems={"center"}>
                    <Grid item xs={12} sm={12} md={12} container>
                      <Typography variant="h8" gutterBottom>
                        <b>{getStringLanguage('Alterar pontos')}</b>
                      </Typography>
                      <Divider />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                      <FormNumber control={control} rules={{ required: true }} errors={errors} name="str" label="STR"
                        onChange={(ev) => {
                          pntResolver(ev, "str");
                        }} />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                      <FormNumber control={control} rules={{ required: true }} errors={errors} name="dex" label="DEX"
                        onChange={(ev) => {
                          pntResolver(ev, "dex");
                        }} />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                      <FormNumber control={control} rules={{ required: true }} errors={errors} name="int" label="INT"
                        onChange={(ev) => {
                          pntResolver(ev, "int");
                        }} />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                      <FormNumber control={control} rules={{ required: true }} errors={errors} readOnly={true} name="pnt" label={getStringLanguage("PONTOS LIVRE")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} gutterBottom></Grid>
                    <Grid item xs={9} sm={9} md={9}></Grid>
                    <Grid item xs={3} sm={3} md={3} container>
                      <Button fullWidth variant="contained" type="submit">{getStringLanguage('CONFIRMAR')}</Button>
                    </Grid>
                  </Grid>
                </Box>

                <Box mt={2}>
                  <Typography variant="h8" gutterBottom>
                    <b>{getStringLanguage('Nação')}</b>
                  </Typography>
                  <Divider />
                  {characters[selectedCharacter].nation == 0 ? <Box>
                    <Typography variant="h8" gutterBottom>
                      {getStringLanguage('A primeira escolha é feita por alzs in game, 1234561 para Capella e 1234562 para Procyon.')}
                    </Typography>
                  </Box> :
                    <Box mt={2} fullWidth className="flex_row align_center justify_center">
                      <Box onClick={() => changeNation(2)} className="flex_col align_center justify_center">
                        <Box className={{ 'nation_image': true, 'procyon': true, 'disable': characters[selectedCharacter].nation != 2 }}>
                        </Box>
                        <Typography mt={2} variant="h8" gutterBottom>
                          <b>Procyon</b>
                        </Typography>
                      </Box>
                      <Box onClick={() => changeNation(1)} className="flex_col align_center justify_center">
                        <Box className={{ 'nation_image': true, 'capella': true, 'disable': characters[selectedCharacter].nation != 1 }}>
                        </Box>
                        <Typography mt={2} variant="h8" gutterBottom>
                          <b>Capella</b>
                        </Typography>
                      </Box>
                    </Box>}
                </Box>

                {(character.style21 == null || character.style21.style == 20) && <  Box className="flex_col justify_center" marginY={4}>
                  <Typography variant="h8" gutterBottom>
                    <b>{'Class Style 21'}</b>
                  </Typography>
                  <Divider />
                  <Box marginY={4} className="flex_row align_center justify_center">
                    <Paper sx={{ width: '240px', marginRight: 2 }}>
                      <Box p={4} className="flex_col justify_center align_center">
                        <Box>
                          <Box marginY={1} className="flex_row align_center justify_center">
                            <img height="32" src={require('assets/fragment.png')} />
                            <Typography marginY={1} variant="h8" gutterBottom>
                              <b>{1000}</b>
                            </Typography>
                          </Box>
                          <Button sx={{ cursor: 'pointer' }} variant="contained" onClick={() => claimStyle()}>{getStringLanguage("COMPRAR")}</Button>
                        </Box>
                      </Box>
                    </Paper>
                  </Box>
                </Box>}

                {(character.style21 == null || character.style21.resets < 10) && < Box className="flex_col justify_center" marginY={4}>
                  <Typography variant="h8" gutterBottom>
                    <b>{'Resetar'}</b>
                  </Typography>
                  <Divider />
                  <Box mt={2}>
                    <Typography variant="h8" gutterBottom>
                      {getStringLanguage('Resetar o personagem fara com que você volte ao lvl 1, porém você ganhará 50 pontos extras, o maximo são 10 resets, VIP reseta level 205, FREE level 215')}
                    </Typography>
                  </Box>
                  <Box marginY={4} className="flex_row align_center justify_center">
                    <Paper sx={{ width: '240px', marginRight: 2 }}>
                      <Box p={4} className="flex_col justify_center align_center">
                        <Box>
                          <Box marginY={1} className="flex_row align_center justify_center">
                            <img height="32" src={require('assets/fragment.png')} />
                            <Typography marginY={1} variant="h8" gutterBottom>
                              <b>{(character.resets + 1) * 15}</b>
                            </Typography>
                          </Box>
                          <Button sx={{ cursor: 'pointer' }} variant="contained" onClick={() => claimReset((character.resets + 1) * 15)}>{getStringLanguage("RESETAR")}</Button>
                        </Box>
                      </Box>
                    </Paper>
                  </Box>
                </Box>}

                {/*character.style21 != null && <Box className="flex_col align_center justify_center">
                          <Typography textAlign={"center"} variant="h8" gutterBottom>
                            <b>{getStringLanguage("Você já possui grau 21 para este personagem")}</b>
                          </Typography>
                          <Box marginY={1} className="flex_row align_center justify_center">
                            <img height="32" src={require('assets/diamond.png')} />
                            <Typography marginY={1} variant="h8" gutterBottom>
                              <b>500k</b>
                            </Typography>
                          </Box>
                          <Button sx={{ cursor: 'pointer' }} variant="error" onClick={() => remStyle()}>{getStringLanguage("REMOVER")}</Button>
                        </Box>*/}

                {character.skills && <Box className="flex_col justify_center" marginY={4}>
                  <Typography variant="h8" gutterBottom>
                    <b>{'Skills'}</b>
                  </Typography>
                  <Divider />
                  <Box marginY={4} className="flex_row align_center justify_center">
                    {character.skills.map((skill, x) =>
                      <Paper sx={{ width: '240px', marginRight: 2 }} key={x}>
                        <Box p={4} className="flex_col justify_center align_center">
                          <img src={URL + '/images/products/' + skill.image + ".png"}></img>

                          <Box sx={{ marginY: 1 }} className="flex_col text_center">
                            <Typography variant="h10" sx={{ lineHeight: "18px" }}>
                              <b>{skill.name}</b>
                            </Typography>
                          </Box>

                          <Box marginY={1} className="flex_row align_center justify_center">
                            <img height="32" src={require('assets/fragment.png')} />
                            <Typography marginY={1} variant="h8" gutterBottom>
                              <b>{skill.price}</b>
                            </Typography>
                          </Box>
                          <Button sx={{ cursor: 'pointer' }} variant="contained" onClick={() => claimSkill(skill.itemId)}>{getStringLanguage("COMPRAR")}</Button>
                        </Box>
                      </Paper>)}
                  </Box>
                </Box>}

                {character.titles && <Box className="flex_col justify_center" marginY={4}>
                  <Typography variant="h8" gutterBottom>
                    <b>{getStringLanguage('Titulos')}</b>
                  </Typography>
                  <Divider />
                  {character.titles.map((title, index) => <Box className="flex_col justify_center">
                    <Box mt={2} fullWidth className="flex_col">
                      <Typography variant="h8" gutterBottom>
                        <b>{title.name}</b>
                      </Typography>
                      {title.received == 1 ? <Box>
                        <Typography variant="h8" gutterBottom>
                          <b>{getStringLanguage("Você já obteve este titulo para este personagem")}</b>
                        </Typography>
                      </Box> :
                        <Box>
                          <LinearProgressWithLabel variant="determinate" value={(100 * title.meta) / title.metaMax} />
                          <Box fullWidth className="flex_col align_center justify_center">
                            <Typography variant="h8" gutterBottom>
                              {title.meta + "/" + title.metaMax}
                            </Typography>
                          </Box>
                          {title.meta == title.metaMax &&
                            <Box onClick={() => obterTitle(index + 1)} className="flex_row jutify_end">
                              <Button variant="contained" onClick={() => { }}>{getStringLanguage('OBTER')}</Button>
                            </Box>
                          }
                        </Box>}
                    </Box>
                  </Box>)}
                </Box>}


                <Box mt={2}>
                  <Typography variant="h8" gutterBottom>
                    <b>{getStringLanguage('Limpar PK')}</b>
                  </Typography>
                  {characters[selectedCharacter].pkPenalty == 0 &&
                    <Typography mt={1} variant="subtitle2" sx={{ lineHeight: "15px" }}>
                      {getStringLanguage("Este personagem não esta PK")}
                    </Typography>}
                  {characters[selectedCharacter].pkPenalty != 0 && <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} gutterBottom></Grid>
                    <Grid item xs={3} sm={3} md={3} container>
                      <Button fullWidth variant="contained" onClick={() => removePkClick()}>{getStringLanguage('LIMPAR')}</Button>
                    </Grid>
                  </Grid>}
                </Box>

                <Box mt={3}>
                  <Typography variant="h8" gutterBottom>
                    <b>{getStringLanguage('Resgatar Bônus')}</b>
                  </Typography>
                  {characters[selectedCharacter].playTime < 60 &&
                    <Typography mt={1} variant="subtitle2" sx={{ lineHeight: "15px" }}>
                      {getStringLanguage("Este personagem ainda não tem tempo de jogo suficiente para resgatar cash bônus")}
                    </Typography>}
                  {characters[selectedCharacter].playTime >= 60 && <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} gutterBottom></Grid>
                    <Grid item xs={3} sm={3} md={3} container>
                      <Button fullWidth variant="contained" onClick={() => rewardClick()}>{getStringLanguage('RESGATAR')}</Button>
                    </Grid>
                  </Grid>}
                </Box>

                <Box mt={3}>
                  <Typography variant="h8" gutterBottom>
                    <b>{getStringLanguage('Atualizar Runas')}</b>
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} gutterBottom></Grid>
                    <Grid item xs={3} sm={3} md={3} container>
                      <Button fullWidth variant="contained" onClick={() => maxClick(1)}>{'ESSENCIAL'}</Button>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} container>
                      <Button fullWidth variant="contained" onClick={() => maxClick(2)}>{'ESSENCIAL + ELEMENTAL'}</Button>
                    </Grid>
                  </Grid>
                </Box>

                <Box mt={3}>
                  <Typography variant="h8" gutterBottom>
                    <b>{getStringLanguage('Deletar Personagem')}</b>
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} gutterBottom></Grid>
                    <Grid item xs={3} sm={3} md={3} container>
                      <Button fullWidth variant="contained" onClick={() => deletClick()}>{getStringLanguage('DELETAR')}</Button>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>

            </TabPanel>)}
          </Box>}
        </Box>

      </Box >

    </Box >
  );
}

export default Characters;
