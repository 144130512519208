export const ROOT = "/";
export const AUTH = "/auth";
export const REGISTER = "/register";
export const LOGIN = "/login";
export const CHARACTERS = "/characters"
export const KIT = "/kit"
export const SHOP = "/shop"
export const SHOP_BONUS = "/bonus"
export const DONATE = "/admdonate"
export const VERIFY = "/verify"
export const PASSWORD = "/password/:key"
export const DOWNLOADS = "/downloads"
export const RANKINGS = "/rankings"
export const VIDEOS = "/videos"
export const VERIFY_KEY = "/verify/:key"
export const SPIN = "/spin"
export const LAUNCHER = "/launcher"
export const PASS = "/pass"
export const SHOP_WAR = "/war"
export const MERCADO_PAGO = "/donate"
export const PIX = "/pix"
export const DAILY = "/daily"
export const CUPOM = "/cupom"
export const VERIFY_CUPOM = "/cupom/:key/:id"
export const PANEL = "/panel"
export const REGISTER_REFER = "/register/:refer";
export const MISSION_DAILY = "/mission"
export const VIP = "/vip"