

import { Paper, Typography, Box, Button, Divider, Grid, Badge } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import PaperHeader from "./PaperHeader";
import { useLayout } from "providers/LayoutProvider";

function Download() {


  const openDownload = (url) => {
    window.open(url, "_blank", "noreferrer");
  }

  const { getStringLanguage } = useLayout();

  return (
    <Box className="flex_col" sx={{ height: '100%' }} >
      
      <Box marginY={2}>
        <PaperHeader icon={<DownloadIcon color="primary" fontSize="large" />}
          title={getStringLanguage("Baixar client completo")} />
      </Box>

      <Box marginY={2} className="flex_col justify_center align_center" sx={{ flex: 1 }}>
        <Grid container spacing={1} alignItems={"center"} justifyContent={"center"}>
          <Grid item xs={6} sm={6} md={6} alignItems={"center"} justifyContent={"center"} container>
            <Paper className="flex_col justify_center align_center text_center"
              sx={{ width: '100%', padding: '40px', marginRight: '20px' }}>
              <img height="32" src={require('assets/googledrive.png')} />
              <Typography marginY={1} ml={1} variant="h8" gutterBottom>
                <b>Google Drive</b><br />  {getStringLanguage("Tamanho") + ": 4.4GB"}
              </Typography>
              <Button fullWidth variant="contained"
                onClick={() => { openDownload("https://drive.google.com/file/d/1N72THeGzdZJuk9pgxkoVAgIrFBeRrDCW/view?usp=sharing") }}>
                {getStringLanguage("BAIXAR")}</Button>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={6} md={6} alignItems={"center"} justifyContent={"center"} container>
            <Paper className="flex_col justify_center align_center text_center"
              sx={{ width: '100%', padding: '40px', marginLeft: '20px' }}>
              <img height="32" src={require('assets/mediafire.png')} />
              <Typography marginY={1} ml={1} variant="h8" gutterBottom>
                <b>Media Fire</b><br />   {getStringLanguage("Tamanho") + ": 4.4GB"}
              </Typography>
              <Button fullWidth variant="contained"
                onClick={() => { openDownload("https://www.mediafire.com/file/ng5wzo7djkku5bz/CabalRedGame.rar/file") }}>
                {getStringLanguage("BAIXAR")}</Button>
            </Paper>
          </Grid>

        </Grid>
      </Box>
      <Box mt={2}>
        <Typography variant="h8" gutterBottom>
          <b>{getStringLanguage("Utilidades")}</b>
        </Typography>
        <Box sx={{ fontSize: '14px' }}>
          <ul>
            <li>{getStringLanguage("Utilizar compatibilidade com Windows 7 pode ajudar em problemas de inicialização")}</li>
            <li>{getStringLanguage("Em caso de Windows Defender ou Anti Virus ativo, pode ser necessário colocar a pasta do cliente nas excessões")}</li>
          </ul>
        </Box>
      </Box>
    </Box>
  );
}

export default Download;
