
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { Box, Button, Divider, Paper, ThemeProvider, Typography, createTheme } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import Banner from "components/Banner";
import PaperHeader from "components/PaperHeader";
import { URL, VIP_COLOR } from "config/CONSTANTS";
import { useLayout } from "providers/LayoutProvider";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BuyPremiumPass, getPass, getPasses } from "services/accountService";
import Lottie from "react-lottie";
import * as passAnimation from 'assets/pass.json';
import { grey } from '@mui/material/colors';
import { lineHeight } from '@mui/system';

function Pass() {

  const defaultAnimation = {
    loop: true,
    autoplay: true,
    animationData: passAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const { mobile, showSuccessDialog, closeSuccessDialog, setLoading, showErrorDialog,
    closeErrorDialog, showAlertDialog, closeAlertDialog, getStringLanguage } = useLayout();

  const [pass, setPass] = useState(null);
  const [rewards, setRewards] = useState(null);
  const passLevels = [1, 2, 3, 4, 5, 6, 7, 8,9,10,11,12,13,14,15,16]

  const navigate = useNavigate();



  useEffect(() => {
    reloadPass(() => { });
  }, [])

  const reloadPass = (callback) => {
    setLoading(true);
    getPasses()
      .then(res => {
        setPass(res.pass)
        setRewards(res.rewards)
        callback();
        setLoading(false);
      })
      .catch((err) => {
        setPass(null)
        callback();
        setLoading(false);
      });
  }

  const claimPass = (level) => {
    showAlertDialog(getStringLanguage("Tem certeza que deseja obter este level do passe ?"),
      () => {
        setLoading(true);
        getPass(level)
          .then((res) => {
            reloadPass(() => {
              setLoading(false);
              showSuccessDialog(getStringLanguage("Passe Level resgatado com sucesso, cheque iventory cash para pegar recompensa"), () => {
                closeSuccessDialog();
              })
            })
          })
          .catch((err) => {
            setLoading(false);
            showErrorDialog(err.message, () => {
              closeErrorDialog();
            })
          });
        closeAlertDialog();
      },
      () => {
        closeAlertDialog();
      })
  }

  const buyPremiumPass = () => {
    showAlertDialog(getStringLanguage("Tem certeza que deseja adiquirir o Passe de Batalha VIP ?"),
      () => {
        setLoading(true);
        BuyPremiumPass()
          .then((res) => {
            reloadPass(() => {
              setLoading(false);
              showSuccessDialog(getStringLanguage("Passe de Batalha VIP Ativado."), () => {
                closeSuccessDialog();
              })
            })
          })
          .catch((err) => {
            setLoading(false);
            showErrorDialog(err.message, () => {
              closeErrorDialog();
            })
          });
        closeAlertDialog();
      },
      () => {
        closeAlertDialog();
      })
  }

  const getPassImage = (level) => {
    var rewardsLevel = rewards.filter(r => r.levelPass == level);
    return rewardsLevel.filter(r => r.image != null)[0].image
  }

  const getPassDesc = (level) => {
    var descstring = "";
    var rewardsLevel = rewards.filter(r => r.levelPass == level && r.title != null);
    var rewardsFix = rewards.filter(r => r.levelPass == 0 && r.title != null);
    rewardsLevel.forEach(r => {
      descstring = descstring + (pass.premium == 1 ? r.premiumItemCount : r.itemCount) + "x" + r.title + "\n"
    })
    rewardsFix.forEach(r => {
      descstring = descstring + (pass.premium == 1 ? r.premiumItemCount : r.itemCount) + "x" + r.title + "\n"
    })
    return descstring;
  }


  const vipTheme = createTheme({
    palette: {
      primary: {
        main: VIP_COLOR
      }
    }
  })

  const PassComponent = () => {
    return <Box sx={{ width: mobile ? '100%' : 1250, padding: mobile ? '15px' : 0 }}>

      <Box sx={{ width: '100%' }} marginY={2}>
        <PaperHeader icon={<LocalActivityIcon color="primary" fontSize="large" />}
          title={getStringLanguage("Passe de Batalha")} />
      </Box>

      <Box sx={{ width: '100%', paddingBottom: '30px' }}>

        <Box marginTop={4} className="flex_col justify_center">
          <Typography variant="h8" gutterBottom>
            <b>{getStringLanguage('Passe do Iniciante - A partir de 02/Julho')}</b>
          </Typography>
          <Divider />
        </Box>
        {pass.premium == 1 && <Box sx={{ width: '100%', textAlign: 'center' }} className="flex_col align_center justify_center">
          <Typography marginY={2} variant="h8" gutterBottom>
            <b>{getStringLanguage('SEU PASSE DE BATALHA É VIP')}</b>
          </Typography>
          <Lottie options={defaultAnimation}
            height={128}
            width={128}
          />
        </Box>}
        <Box marginY={4} className="flex_col justify_center">
          <Box sx={{ width: '100%', textAlign: 'center' }} className="flex_col justify_center">
            <Typography marginY={2} variant="h8" gutterBottom>
              <b>{pass.exp + " XP"}</b>
            </Typography>
            <LinearProgress variant="determinate" value={(100 * pass.exp) / 800} />
          </Box>

          <Box marginY={4} sx={{ width: '100%', overflowX: 'scroll', paddingBottom: '15px' }}
            className={"flex_row align_center " + (pass.premium != 1 ? "" : "vip_scroll")}>
            {passLevels.map((passCount) =>
              <Paper elevation={4} sx={{
                width: '300px', marginRight: '20px', backgroundColor: '#121212', color: 'white',
                backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09))'
              }}>
                <Box p={4} className="flex_col align_center">
                  <img className="shop_product"
                    width={146} height={146} src={URL + '/images/products/' + getPassImage(passCount) + ".png"}></img>
                  <Box sx={{ marginY: 2 }} className="flex_col text_center">
                    <Typography variant="h10" sx={{ lineHeight: "18px" }}>
                      <b>LEVEL {passCount} - {(passCount * 100) + " XP"}</b>
                    </Typography>

                    <Box className="flex_col justify_center"
                      sx={{ width: '100%', height: '100px' }}>
                      <Typography variant="subtitle2" sx={{ lineHeight: "18px" }}>
                        {getPassDesc(passCount)}
                      </Typography>
                    </Box>
                  </Box>

                  <Box className="flex_col justify_center align_center" sx={{ width: '100%', height: '50px' }}>
                    {pass.levelPass >= passCount &&
                      <Typography marginY={2} variant="h8" gutterBottom>
                        <b>{getStringLanguage("FINALIZADO")}</b>
                      </Typography>
                    }

                    {pass.levelPass < passCount && ((pass.exp < passCount * 100)) &&
                      <Typography marginY={2} variant="h8" gutterBottom>
                        <b>{getStringLanguage("INCOMPLETO")}</b>
                      </Typography>
                    }

                    {pass.levelPass < passCount && ((pass.exp >= passCount * 100)) &&
                      <Button fullWidth variant="contained" onClick={() => claimPass(passCount)}>{getStringLanguage('RESGATAR')}</Button>
                    }
                  </Box>

                </Box>
              </Paper>
            )}

          </Box>
          
        </Box>
      </Box>

    </Box >
  }

  return (
    <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }} className="flex_col align_center">

      <Banner image={require("assets/pass.jpg")} height={'600px'}
        position={mobile ? 'center' : "100% 60%"} title={getStringLanguage("Passe de Batalha")} />

      {pass && <Box>
        {pass.premium != 1 && <PassComponent />}
        {pass.premium == 1 && <ThemeProvider theme={vipTheme}>
          <PassComponent />
        </ThemeProvider>}
      </Box>}



    </Box >
  );
}

export default Pass;
