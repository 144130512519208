
import { Paper, Typography, Box } from "@mui/material";
import News from "../../components/News";
import LastWar from "components/LastWar";
import Ranking from "components/Ranking";
import Banner from "components/Banner";
import { useAuth } from "providers/AuthProvider";
import { useLayout } from "providers/LayoutProvider";
import HomeUser from "components/HomeUser";
import Dungeons from "components/Dungeons";

function Home() {

  const { user } = useAuth();
  const { mobile, getStringLanguage } = useLayout();

  return (
    <Box sx={{ height: '100%', overflow: 'hidden' }}>

      <Box sx={{ position: 'relative', width: '100%', height: user ? '600px' : (mobile ? '600px' : '800px') }}>

        {<Banner image={require("assets/banner_home.jpg")} height={user ? '600px' : (mobile ? '600px' : '800px')}
          position={mobile ? 'center' : (user ? "100% 25%" : "100% 35%")}
          title={"Cabal Red Game"}
          subtitle={user ? null : getStringLanguage("Crie sua conta agora e resgate um kit inicial incrível para ajudar iniciar sua jornada")}
          button={user ? null : getStringLanguage("JOGAR")} />}

        <div className="header-shadow">
          <div className="paralax-disabled paralax-sparks">
            <div className="sparks_1"></div>
            <div className="feathers"></div>
            <div className="sparks_2"></div>
          </div>
          <div className="container">
            <div className="sparks">
              <div className="sparks_3"></div>
              <div className="sparks_4"></div>
              <div className="sparks_5"></div>
              <div className="sparks_6"></div>
            </div>
          </div>
        </div>

      </Box>

      {<HomeUser />}

      <Box>
        <Box mb={2} className="flex_row justify_center">
          <Box sx={{ width: mobile ? '100%' : 1250, padding: mobile ? '15px' : 0 }}>
            <LastWar />
          </Box>
        </Box>
        <Box marginY={2} className="flex_row justify_center">
          <Box sx={{ width: mobile ? '100%' : 1250, padding: mobile ? '15px' : 0 }}>
            <Ranking />
          </Box>
        </Box>
        <Box mb={2} className="flex_row justify_center">
          <Box sx={{ width: mobile ? '100%' : 1250, padding: mobile ? '15px' : 0 }}>
            <Dungeons />
          </Box>
        </Box>
      </Box>
    </Box >
  );
}

export default Home;
