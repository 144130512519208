
import { Paper, Typography, Box, Button, Divider, TextField, Grid, Tooltip, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useLayout } from "providers/LayoutProvider";
import { decodificar, getCharacters, removePk, updateNation, updatePoints } from "services/characterService";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import GroupIcon from '@mui/icons-material/Group';
import { useForm } from "react-hook-form";
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import { buyProduct, buyShopWar, getCategories, getProducts, getShopWar, transferCharShard } from "services/shopService";
import { useCash } from "providers/CashProvider";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PaperHeader from "components/PaperHeader";
import ShieldIcon from '@mui/icons-material/Shield';
import { URL } from "config/CONSTANTS";
import Banner from "components/Banner";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import CloseIcon from '@mui/icons-material/Close';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function ShopWar() {

  const [categories, setCategories] = useState(null);
  const [selectedCategorie, setSelectedCategorie] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedQty, setSelectedQty] = useState(1);

  const [products, setProducts] = useState([]);
  const { mobile, showSuccessDialog, closeSuccessDialog, setLoading, showErrorDialog,
    closeErrorDialog, showAlertDialog, closeAlertDialog, getStringLanguage, language } = useLayout();
  const { shard, updateCash } = useCash();

  const [openDialog, setOpenDialog] = useState(false);

  const [openTransferDialog, setOpenTransferDialog] = useState(false);
  const [selectedTransferChar, setSelectedTransferChar] = useState("");

  const {
    control,
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
    setValue,
    getValues
  } = useForm({});

  useEffect(() => {
    setLoading(true);
    loadProducts(() => {
      setLoading(false);
    })
  }, [])

  const loadProducts = (callback) => {
    getShopWar()
      .then((res) => {
        setProducts(res);
        callback();
      })
      .catch((err) => {
        setProducts(null);
        callback();
      });
  }

  const handleChange = (event, newValue) => {
    setLoading(true)
    setSelectedCategorie(newValue);
    loadProducts(categories[newValue].id, () => {
      setLoading(false);
    })
  };

  const buy = (product) => {
    setSelectedProduct(product);
    setSelectedQty(1);
    setOpenDialog(true)
  }

  const confirmBuy = () => {
    setOpenDialog(false);
    setLoading(true)
    buyShopWar({ itemId: selectedProduct.id, itemQty: selectedQty })
      .then((res) => {
        updateCash();
        setLoading(false);
        showSuccessDialog(getStringLanguage("Item comprado com sucesso, confira seu Invetario Cash no jogo"), () => {
          closeSuccessDialog();
        })

      })
      .catch((err) => {
        setLoading(false);
        showErrorDialog(err.message, () => {
          closeErrorDialog();
        })
      });
  }

  const replaceProductTittle = (title) => {
    title = title.replace("AA", "FA");
    title = title.replace("EA", "FB");
    title = title.replace("GA", "FS");
    title = title.replace("GU", "WA");
    title = title.replace("MA", "WI");
    title = title.replace("DU", "BL");
    title = title.replace("AT", "FG");
    title = title.replace("Lutador", "Fighter");
    title = title.replace("Sabio", "Sage");
    title = title.replace("Magico", "Mage");
    return title;
  }

  const cancelBuy = () => {
    setOpenDialog(false);
  }

  const transferShard = () => {
    setOpenTransferDialog(false);
    setLoading(true)
    transferCharShard(selectedQty, selectedTransferChar)
      .then((res) => {
        updateCash();
        setLoading(false);
        showSuccessDialog(getStringLanguage("War fragmento transferido com sucesso."), () => {
          closeSuccessDialog();
        })

      })
      .catch((err) => {
        setLoading(false);
        showErrorDialog(err.message, () => {
          closeErrorDialog();
        })
      });
  }

  return (
    <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }} className="flex_col align_center">

      {<Banner image={require("assets/war.jpg")} height={'400px'}
        position={mobile ? 'center' : "100% 30%"} title={getStringLanguage("Loja Guerra")} />}


      <Box sx={{ width: mobile ? '100%' : 1250, padding: mobile ? '15px' : 0 }}>

        <Box sx={{ width: '100%' }} marginY={2}>
          <PaperHeader icon={<ShieldIcon color="primary" fontSize="large" />}
            title={getStringLanguage("Loja Guerra")} />
        </Box>

        <Box marginY={4} className="flex_col justify_center">
          <Typography variant="h8" gutterBottom>
            <b>{getStringLanguage('Troque fragmentos de guerra em recompensas especiais')}</b>
          </Typography>
          <Divider />
          <Box marginY={4} sx={{ width: '100%', textAlign: 'center' }} className="flex_row align_center justify_center">
            <img height="64" src={require('assets/fragment.png')} />
            <Typography marginY={2} variant="h4" gutterBottom>
              <b>{shard}</b>
            </Typography>

            {<Box marginX={2}>
              <Tooltip title={getStringLanguage("Transferir")}>
                <IconButton onClick={() => { setOpenTransferDialog(true) }}>
                  <CompareArrowsIcon />
                </IconButton>
              </Tooltip>
            </Box>}
          </Box>
        </Box>

        <Box sx={{ width: '100%', paddingBottom: '30px' }}>

          <Grid container spacing={1}>
            {products.map((product, x) =>
              <Grid item xs={12} sm={12} md={4} container>
                <Paper sx={{ width: '100%' }} key={x}>

                  <Box p={4} className="flex_col align_center">

                    <img className="shop_product"
                      width={146} height={146} src={URL + '/images/products/' + product.image + ".png"}></img>

                    <Box sx={{ marginY: 2 }} className="flex_col text_center">
                      <Typography variant="h10" sx={{ lineHeight: "18px" }}>
                        <b>{language == 0 ? product.description : replaceProductTittle(product.description)}</b>
                      </Typography>
                      <Typography variant="subtitle2" sx={{ lineHeight: "18px" }}>
                        {product.title}
                      </Typography>
                    </Box>

                    <Box marginY={2} className="flex_row align_center justify_center">
                      <img height="32" src={require('assets/fragment.png')} />
                      <Typography marginY={2} variant="h8" gutterBottom>
                        <b>{product.price}</b>
                      </Typography>
                    </Box>

                    <Button sx={{ cursor: 'pointer' }} variant="contained" onClick={() => buy(product)}>{getStringLanguage("COMPRAR")}</Button>
                  </Box>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Box>

      </Box>



      {selectedProduct != null && <Dialog
        open={openDialog}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box className="flex_row align_center">
            <StoreOutlinedIcon sx={{ marginRight: '15px' }} color="warning" />
            {getStringLanguage("Comprar")}
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {<Box sx={{ padding: '15px' }}>
              <TextField
                fullWidth
                value={selectedQty}
                label={getStringLanguage("Selecionar quantidade")}
                onChange={event => setSelectedQty(event.target.value < 1 ? 1 : event.target.value)}
                autoFocus={true}
              ></TextField>
            </Box>}
            <Typography variant="h10" sx={{ color: 'white' }}>
              {getStringLanguage("Comprar") + " " + selectedQty + "x "
                + (selectedProduct.description) + getStringLanguage(" por ")}
              {(selectedProduct.price * selectedQty).toLocaleString('pt-BR') + " war shards ?"}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { cancelBuy() }} autoFocus>
            {getStringLanguage("Não")}
          </Button>
          <Button onClick={() => { confirmBuy() }} autoFocus>
            {getStringLanguage("Sim")}
          </Button>
        </DialogActions>
      </Dialog>}

      <Dialog
        open={openTransferDialog}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box className="flex_row align_center">
            <StoreOutlinedIcon sx={{ marginRight: '15px' }} color="warning" />
            {getStringLanguage("Transferir") + " war fragments"}
          </Box>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => { setOpenTransferDialog(false) }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

            <Box mb={2} className="flex_col align_center">
              <img height="64" src={require('assets/fragment.png')} />
            </Box>


            <Grid mb={2} container alignItems={"center"} justifyContent={"center"}>
              <Grid item xs={12} sm={12} md={8} alignItems={"center"} justifyContent={"center"} container>
                {<TextField
                  fullWidth
                  value={selectedTransferChar}
                  label={"Nick Char"}
                  onChange={event => setSelectedTransferChar(event.target.value)}
                  autoFocus={true}
                ></TextField>}
              </Grid>
              <Grid item xs={12} sm={12} md={4} alignItems={"center"} justifyContent={"center"} container>
                {<TextField
                  fullWidth
                  value={selectedQty}
                  label={getStringLanguage("Selecionar quantidade")}
                  onChange={event => setSelectedQty(event.target.value < 1 ? 1 : event.target.value)}
                  autoFocus={true}
                ></TextField>}
              </Grid>
            </Grid>

            <Typography variant="h10" sx={{ color: 'white' }}>
              {getStringLanguage("Transferir") + " " + selectedQty + "x war shards para "
                + selectedTransferChar + "?"}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color={"primary"} onClick={() => { transferShard() }} autoFocus>
            {getStringLanguage("Transferir").toUpperCase()}
          </Button>
        </DialogActions>
      </Dialog>







    </Box >
  );
}

export default ShopWar;
