import { API_URL, SYSTEM_ERROR } from "../config/CONSTANTS";
import axios from 'axios'
import authHeader from "./authHeader";

const accountUrl = API_URL + "/Character";

export const getCharacters = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(accountUrl + "/get", { headers: authHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const updatePoints = (body) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(accountUrl + "/points", body, { headers: authHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
}


export const updateNation = (body) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(accountUrl + "/nation", body, { headers: authHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
}

export const removePk = (characterIdx) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(accountUrl + "/pk?characterIdx=" + characterIdx, { headers: authHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
}

export const Delete = (characterIdx) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(accountUrl + "/delete?characterIdx=" + characterIdx, { headers: authHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
}
export const MaxRune = (characterIdx, type) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(accountUrl + "/maxrune?characterIdx=" + characterIdx+ "&type=" + type, { headers: authHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
}




export const reward = (characterIdx) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(accountUrl + "/reward?characterIdx=" + characterIdx, { headers: authHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
}

export const getTitles = (characterIdx) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(accountUrl + "/titles?characterIdx=" + characterIdx, { headers: authHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
}

export const getTitle = (characterIdx, title) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(accountUrl + "/title?characterIdx=" + characterIdx + "&title=" + title, { headers: authHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
}

export const getSkills = (characterIdx) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(accountUrl + "/skills?characterIdx=" + characterIdx, { headers: authHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
}

export const getSkill = (characterIdx, itemId) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(accountUrl + "/skill?characterIdx=" + characterIdx + "&itemId=" + itemId, { headers: authHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
}

export const getStyle = (characterIdx) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(accountUrl + "/style?characterIdx=" + characterIdx, { headers: authHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
}

export const checkStyle = (characterIdx) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(accountUrl + "/getStyle?characterIdx=" + characterIdx, { headers: authHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
}

export const getReset = (characterIdx) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(accountUrl + "/reset?characterIdx=" + characterIdx, { headers: authHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
}


export const removeStyle = (characterIdx) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(accountUrl + "/removeStyle?characterIdx=" + characterIdx, { headers: authHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
}

function hexdec(dec) {
  return parseInt(dec, 16);
}

export function getClassSingle(classId) {
  var classSingle = {
    "1": 'gu',
    "2": 'du',
    "3": 'ma',
    "4": 'aa',
    "5": 'ga',
    "6": 'ea',
    "7": 'gl',
    "8": 'at',
    "9": 'mn',
  };
  return classSingle[classId.substr(0,1)].toUpperCase();
}


export function decodificar(megaHonra, language) {
  var classRank = Math.round(((((megaHonra % hexdec(4000000)) % hexdec(20000)) % hexdec(2000)) % hexdec(100)) / 8);
  var classType = ((((megaHonra % hexdec(4000000)) % hexdec(20000)) % hexdec(2000)) % hexdec(100)) - ((classRank - 1) * 8);

  if (language == 0 || language == null) {
    var classSingle = {
      "4": 'aa',
      "5": 'ga',
      "6": 'ea',
      "9": 'gu',
      "10": 'du',
      "11": 'ma',
      "12": 'aa',
      "13": 'ga',
      "14": 'ea',
    };

    var className = {
      "4": 'Arqueiro Arcano',
      "5": 'Guardião Arcano',
      "6": 'Espadachim Arcano',
      "9": 'Guerreiro',
      "10": 'Duelista',
      "11": 'Mago',
      "12": 'Arqueiro Arcano',
      "13": 'Guardião Arcano',
      "14": 'Espadachim Arcano',
    };

    var classSingleResult = classSingle[classType].toUpperCase();
    var classNameResult = className[classType];

    if (classSingleResult == 'AA') {
      if (megaHonra > 805306368) {
        classSingleResult = 'AT';
        classNameResult = 'Atirador Arcano';
      }
    }

    if (classSingleResult == 'GU') {
      if (megaHonra > 536870912) {
        classSingleResult = 'GL';
        classNameResult = 'Gladiador';
      }
    }

    if (classSingleResult == 'MA') {
      if (megaHonra > 1073741824) {
        classSingleResult = 'MN';
        classNameResult = 'Mago Negro';
      }
    }

    return { classType, classSingle: classSingleResult, className: classNameResult };
  } else {
    var classSingle = {
      "4": 'fa',
      "5": 'fs',
      "6": 'fb',
      "9": 'wa',
      "10": 'bl',
      "11": 'wi',
      "12": 'fa',
      "13": 'fs',
      "14": 'fb',
    };

    var className = {
      "4": 'Force Archer',
      "5": 'Force Shilder',
      "6": 'Force Blader',
      "9": 'Warrior',
      "10": 'Blader',
      "11": 'Wizard',
      "12": 'Force Archer',
      "13": 'Force Shilder',
      "14": 'Force Blader',
    };

    var classSingleResult = classSingle[classType].toUpperCase();
    var classNameResult = className[classType];

    if (classSingleResult == 'FA') {
      if (megaHonra > 805306368) {
        classSingleResult = 'FG';
        classNameResult = 'Force Gunner';
      }
    }

    if (classSingleResult == 'WA') {
      if (megaHonra > 536870912) {
        classSingleResult = 'GL';
        classNameResult = 'Gladiador';
      }
    }

    if (classSingleResult == 'WI') {
      if (megaHonra > 1073741824) {
        classSingleResult = 'DM';
        classNameResult = 'Dark Mage';
      }
    }

    return { classType, classSingle: classSingleResult, className: classNameResult };
  }
}


