export const SYSTEM_ERROR = "System error. Please try again later!";
export const PRIMARY_COLOR = '#b11b2f;';
export const SECONDARY_COLOR = '#0d0d0d';
export const VIP_COLOR = '#0188d1';
export const ERROR_COLOR = '#de5555';
export const API_URL = 'https://api2.playredgame.com:5001/api';
//export const API_URL = 'http://177.54.147.181:5002/api'; 
//export const API_URL = 'http://localhost:5000/api'; 
//export const URL_VEHICLES = 'http://www.mocky.io/v2/5eb553df31000060006994a8';
export const URL = 'https://playredgame.com';


