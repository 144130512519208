

import { Paper, Typography, Box, Button, Divider, Grid, Badge } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import { useLayout } from "providers/LayoutProvider";
import { useState } from "react";

function Footer() {

  const { showTermsDialog, language } = useLayout();
  const [pageYOffset, setPageYOffset] = useState(0);

  const openTerms = () => {
    showTermsDialog(language);
  }

  return (
    <Box className="flex_col" sx={{ padding: '150px', width: '100%', background: '#000' }} >
      <Typography variant="h10" sx={{ lineHeight: "30px", textAlign: 'center', color: 'white' }}>
        <b>2024 © Cabal Red Game</b>
      </Typography>
      <Typography variant="h10" sx={{ lineHeight: "15px", textAlign: 'center' }}>
        {"Images and trademarks belong to their respective owners."}
      </Typography>
      <Typography variant="subtitle2" sx={{ lineHeight: "15px", textAlign: 'center' }}>
        {"We are in no way affiliated / associated / partnered with ESTsoft Corp., ESTsoft Inc., NBird LLC, NBird, SDO, SNDO and SNDA."}
      </Typography>

      <Typography mt={2} onClick={() => openTerms()}
        variant="subtitle2" sx={{ cursor: 'pointer', lineHeight: "15px", textAlign: 'center', textDecoration: 'underline' }}>
        {"Terms of Service"}
      </Typography>
    </Box>
  );
}





export default Footer;
