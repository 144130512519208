import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import { Box, Button, Paper, Typography, Divider, IconButton, Chip } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import * as alarmAnimation from 'assets/alarm.json';
import Banner from "components/Banner";
import PaperHeader from "components/PaperHeader";
import { ERROR_COLOR } from "config/CONSTANTS";
import { useAuth } from "providers/AuthProvider";
import { useLayout } from "providers/LayoutProvider";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import Moment from 'react-moment';
import { useNavigate } from "react-router-dom";
import { getCheckDaily, getDaily, getIp } from "services/accountService";
import { getMissionDaily, getMissionDailys } from 'services/missionService';



function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}


function MissionDaily() {

  const { mobile, showSuccessDialog, closeSuccessDialog, setLoading, showErrorDialog,
    closeErrorDialog, showAlertDialog, closeAlertDialog, getStringLanguage } = useLayout();
  const { user } = useAuth();
  const [pass, setPass] = useState(null);
  const [missionDailys, setMissionDailys] = useState(null);
  const [error, setError] = useState("Carregando...");

  const defaultAnimation = {
    loop: true,
    autoplay: true,
    animationData: alarmAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    checkMissionDailys();
    console.log(getHardwareId());
  }, [])

  const checkMissionDailys = () => {
    setLoading(true);

    getMissionDailys()
      .then(res => {
        setMissionDailys(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });

  }

  const claimMissionDaily = (id) => {
    setLoading(true);
    getMissionDaily(id)
      .then(res => {
        checkMissionDailys();
        showSuccessDialog(res.message, () => {
          closeSuccessDialog();
        })
      })
      .catch((err) => {
        setError(err.message)
        setLoading(false);
      });
  }

  const getHardwareId = () => {
    var navigator_info = window.navigator;
    var screen_info = window.screen;
    var uid = navigator_info.mimeTypes.length;
    uid += navigator_info.userAgent.replace(/\D+/g, '');
    uid += navigator_info.plugins.length;
    uid += screen_info.height || '';
    uid += screen_info.width || '';
    uid += screen_info.pixelDepth || '';
    return uid;
  }

  return (
    <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }} className="flex_col align_center">

      {<Banner image={require("assets/mission.jpg")} height={'500px'}
        position={mobile ? 'center' : "100% 100%"} title={getStringLanguage("Missao Diaria")} />}


      <Box sx={{ width: mobile ? '100%' : 1250, padding: mobile ? '15px' : 0 }} className="flex_col align_center">

        <Box sx={{ width: '100%' }} marginY={2}>
          <PaperHeader icon={<AlarmOnIcon color="primary" fontSize="large" />}
            title={getStringLanguage("Missão Diária")} />
        </Box>

        <Paper sx={{ width: mobile ? '100%' : 512 }}>
          <Box p={4} className="flex_col align_center">

            {/* <img className="shop_product"
              width={146} height={146} src={URL + '/images/products/' + constPass.image + ".png"}></img> */}
            <Lottie options={defaultAnimation}
              height={256}
              width={256}
            />
            <Box sx={{ marginY: 2 }} className="flex_col text_center">
              <Typography variant="h10">
                <b>{<Moment format="DD/MM/YYYY">{new Date()}</Moment>}</b>
              </Typography>

              <Box className="flex_col justify_center"
                sx={{ width: '100%', height: '100px' }}>
                <Typography variant="subtitle2">
                  {getStringLanguage('Complete as seguintes missões para ganhar recompensas')}
                </Typography>
              </Box>
            </Box>

            {missionDailys && <Box className="flex_col justify_center" mb={4} sx={{ width: '100%' }}>
              <Divider />
              {missionDailys.map((mission, index) => <Box my={2} className="flex_row align_center">
                <Box mr={2}>
                  <Chip color="primary" label={index + 1} />
                </Box>
                <Box mt={2} fullWidth className="flex_col justify_center" sx={{ flex: 1, margin: 0 }}>
                  <Typography variant="h8">
                    <b>{mission.countMax + "x " + mission.missionDungeon}</b>
                  </Typography>
                  {mission.completed == 1 ? <Box>
                    <Typography color="primary" variant="h8">
                      <b>{getStringLanguage("Completa")}</b>
                    </Typography>
                  </Box> :
                    <Box>
                      <LinearProgressWithLabel variant="determinate" value={(100 * mission.count) / mission.countMax} />
                      <Box fullWidth className="flex_col align_center justify_center">
                        <Typography variant="h8">
                          {mission.count + "/" + mission.countMax}
                        </Typography>
                      </Box>
                      {mission.count >= mission.countMax &&
                        <Box onClick={() => claimMissionDaily(mission.id)} className="flex_row jutify_end">
                          <Button variant="contained" onClick={() => { }}>{getStringLanguage('OBTER')}</Button>
                        </Box>
                      }
                    </Box>}
                </Box>
              </Box>)}
            </Box>}


          </Box>
        </Paper>

      </Box >



    </Box >
  );
}

export default MissionDaily;
