
import { Paper, Typography, Box, Button, TextField, Grid, Divider, IconButton } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "providers/AuthProvider";
import AddReactionIcon from '@mui/icons-material/AddReaction';
import { useLayout } from "providers/LayoutProvider";
import { confirmDonateRequest, getDonates, requestDonate } from "services/cashService";
import PIX from "react-qrcode-pix";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import PaperHeader from "components/PaperHeader";
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';


function Pix() {

  const navigate = useNavigate();

  useEffect(() => { }, [])

  const { user } = useAuth();
  const [selectedDonate, setSelectedDonate] = useState(10);
  const [selectedDonateRow, setSelectedDonateRow] = useState(null);
  const [donates, setDonates] = useState([]);
  const { mobile, showSuccessDialog, closeSuccessDialog, setLoading, showErrorDialog,
    closeErrorDialog, showAlertDialog, closeAlertDialog, language, getStringLanguage } = useLayout();
  const [minimalPIX, setMinimalPIX] = useState("");
  const [fullPIX, setFullPIX] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);


  useEffect(() => {
    updateDonates();
  }, [])

  const updateDonates = () => {
    getDonates()
      .then((res) => {
        setDonates(res);
      })
      .catch((err) => {
        setDonates([]);
      });
  }

  const handleDonate = () => {
    if (selectedDonate < 10) {
      showErrorDialog(getStringLanguage("Valor inválido"), () => {
        closeErrorDialog();
      })
    } else {
      confirmDonate();
    }
  };

  const confirmDonate = () => {
    showAlertDialog(getStringLanguage("Tem certeza que deseja realizar a doação?"),
      () => {
        setLoading(true);
        requestDonate({ cash: selectedDonate, attachment: "" })
          .then((res) => {
            updateDonates();
            setLoading(false);
            closeSuccessDialog();
            setFullPIX(parseFloat(selectedDonate));
            setOpenDialog(true);
          })
          .catch((err) => {
            setLoading(false);
            showErrorDialog(err.message, () => {
              closeErrorDialog();
            })
          });
        closeAlertDialog();
      },
      () => {
        closeAlertDialog();
      })
  }

  const cancelPix = () => {
    setOpenDialog(false);
  }

  const confirmPix = () => {
    setOpenDialog(false);
    if (user.isAdm == 1) {
      setLoading(true);
      confirmDonateRequest(selectedDonateRow.id)
        .then((res) => {
          updateDonates();
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          showErrorDialog(err.message, () => {
            closeErrorDialog();
          })
        });
    }
  }

  const realoadPix = (pix) => {
    if (pix.status == 0) {
      setSelectedDonateRow(pix)
      setFullPIX(parseFloat(pix.cash));
      setOpenDialog(true);
    }
  }

  const copyPix = (event) => {
    navigator.clipboard.writeText(minimalPIX)
    setOpenSnack(true)
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const openUrl = (url) => {
    window.open(url, "_blank", "noreferrer");
  }

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  const action = (
    <Fragment>
      <Button color="secondary" size="small" onClick={handleCloseSnack}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );


  return (
    <>{user &&

      <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }} className="flex_col align_center">

        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
          message="CÓDIGO COPIADO"
          action={action}
        />

        <Box sx={{ width: mobile ? '100%' : 1250, padding: mobile ? '15px' : 0 }}>
          {user.isAdm == 0 && <Box sx={{ width: '100%' }} marginY={2}>
            <PaperHeader icon={<AddReactionIcon color="primary" fontSize="large" />}
              title={getStringLanguage("Doação")} />
            <Box marginY={4}>
              <Box className="flex_col">
                <Typography variant="subtitle2" sx={{ lineHeight: "18px" }}>
                  {getStringLanguage("Faça uma doação e ajude a manter nosso servidor sempre on-line")}<br />
                  {getStringLanguage("A cada R$10,00 doado você será retribuido em 50.000 de cash")}<br />
                  {getStringLanguage("A cada R$50,00 1x Ticket da Roleta")}<br /><br />
                  <b>{getStringLanguage("Promoção Versão - Doações a partir de R$100,00 ganhará o dobro do valor em cash bonus")}<br /></b>
                </Typography>
              </Box>
            </Box>
          </Box>}

          {user.isAdm == 0 && <Box marginY={2}>
            <Grid container spacing={1} alignItems={"center"}>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  fullWidth
                  value={selectedDonate}
                  label={getStringLanguage("Valor R$")}
                  onChange={event => setSelectedDonate(event.target.value)}
                  autoFocus={true}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={12} container>
                <Typography variant="h8" gutterBottom>
                  {getStringLanguage("Total de cash recebidos: ")}<b>{((selectedDonate) * 5000).toLocaleString('pt-BR')} cash </b><br />
                  {selectedDonate >= 100 && <div><b>+{((selectedDonate) * 10000).toLocaleString('pt-BR')} cash bonus </b></div>}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} gutterBottom></Grid>
              <Grid item xs={9} sm={9} md={9}></Grid>
              <Grid item xs={3} sm={3} md={3} container>
                <Button fullWidth variant="contained" onClick={() => { handleDonate() }}>{getStringLanguage("DOAR")}</Button>
              </Grid>
            </Grid>
          </Box>}

          <Box marginY={2} className="flex_col">
            <Typography mb={2} variant="h10" sx={{ lineHeight: "15px" }}>
              <b>{getStringLanguage('HISTÓRICO DE DOAÇÕES')}</b>
            </Typography>
            <Table aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">ID</StyledTableCell>
                  <StyledTableCell >{getStringLanguage('VALOR')}</StyledTableCell>
                  <StyledTableCell >STATUS</StyledTableCell>
                  <StyledTableCell align="center">{getStringLanguage('DATA')}</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {donates.map((row, i) => (
                  <StyledTableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, 'cursor': row.status == 0 ? 'pointer' : 'auto' }}
                    onClick={() => { realoadPix(row) }}>
                    <StyledTableCell align="center" component="th" scope="row">
                      {user.isAdm == 0 ? (i + 1) : row.id}
                    </StyledTableCell>
                    <StyledTableCell >
                      <span className="flex_row align_center">
                        {getStringLanguage("R$ ") + row.cash.toLocaleString('pt-BR')}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell >
                      {row.status == 0 ? getStringLanguage('EM ANÁLISE') : getStringLanguage('FINALIZADO')}
                    </StyledTableCell>
                    {user.isAdm == 1 && <StyledTableCell style={{ maxWidth: '150px' }} component="th" scope="row">
                      {row.attachment}
                    </StyledTableCell>}
                    <StyledTableCell align="center">{(new Date(row.date)).toLocaleString()}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>

        {fullPIX != null && <Dialog
          open={openDialog}
          onClose={() => { }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Box className="flex_row align_center">
              <QrCodeScannerIcon mr={2} color="primary" fontSize="large" />
              {user.isAdm == 0 ? getStringLanguage("Pagamento") : "Confirmar Doação"}
            </Box>
          </DialogTitle>
          <DialogContent>

            <DialogContentText id="alert-dialog-description">

              {language == 0 && <Box>
                <Typography variant="subtitle2" sx={{ marginY: 2, lineHeight: "18px" }}>
                  QR CODE
                </Typography>
                <PIX
                  pixkey="cabalclassicbr@gmail.com"
                  merchant="Cabal Red Game"
                  city="SP"
                  amount={fullPIX}
                  onLoad={setMinimalPIX}
                />
              </Box>}


              {language == 0 && <Box sx={{ cursor: 'pointer' }} onClick={copyPix}>
                <Typography variant="subtitle2" sx={{ marginY: 2, lineHeight: "18px" }}>
                  CÓDIGO COPIA E COLA
                </Typography>
                <TextField
                  fullWidth
                  value={minimalPIX}
                  onClick={copyPix}
                  autoFocus={true}
                ></TextField>
              </Box>}
              <Box className="flex_col align_center">
                {language == 0 && <Typography mt={4} variant="h10" sx={{ lineHeight: "15px" }}>
                  <b>Ou utilize, </b>
                </Typography>}
                {language == 1 && <Typography mt={4} variant="h10" sx={{ lineHeight: "15px" }}>
                  <b>Send the donation to the PAYPAL account and email <br /><b>cabalclassicbr@gmail.com</b><br /> with the amount indicated</b>
                  <br /><br /> <b>or,</b><br /><br />
                  <b> To use Western Union, contact us via discord ticket.,<br />
                    If you have any questions about donating, feel free to contact support.</b>
                </Typography>}
                {language == 0 && <Box className="flex_row align_center">
                  <img width="128" style={{ cursor: 'pointer' }} onClick={() => { openUrl("https://link.mercadopago.com.br/cabalonlineclassic") }} src={require('assets/mercado-pago.png')} />
                  <img width="128" style={{ cursor: 'pointer' }} onClick={() => { alert("Para PayPal enviar a doação para o e-mail: cabalclassicbr@gmail.com") }} src={require('assets/paypal.png')} />
                </Box>}
                {language == 1 && <Box marginY={4} className="flex_row align_center">
                  <img width="128" src={require('assets/paypal.png')} />
                  <img width="128" style={{ marginLeft: '30px' }} src={require('assets/western.png')} />
                </Box>}
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { confirmPix() }} autoFocus>
              FECHAR
            </Button>
          </DialogActions>
        </Dialog>
        }

      </Box >
    }</>);
}

export default Pix;
