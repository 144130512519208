import React from "react";
import { Typography } from '@mui/material';

export const NotFound = () => {
  return (
    <>
      <Typography variant="h2">404: page not found!</Typography>
    </>
  );
};
